import React from 'react'
import SwiperMain from './SwiperMain'


const Projects = () => {

  
  return (
    <>
      <SwiperMain/>
    </>
  )
}

export default Projects